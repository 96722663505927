var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4"},[_c('admin-header'),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.groupFilterOptions,"label":"Major/Minor Filter","outlined":"","hide-details":"","dense":""},model:{value:(_vm.filterGroup),callback:function ($$v) {_vm.filterGroup=$$v},expression:"filterGroup"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.classFilterOptions,"label":"Class Level Filter","outlined":"","hide-details":"","dense":"","multiple":""},model:{value:(_vm.filterClass),callback:function ($$v) {_vm.filterClass=$$v},expression:"filterClass"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.eventList,"label":"Event","item-text":"name","item-value":"_id","outlined":"","hide-details":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.stringFormatDate(item.date)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.students || 0)+" Attended")])],1)]}}]),model:{value:(_vm.filterEvent),callback:function ($$v) {_vm.filterEvent=$$v},expression:"filterEvent"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.eventAttendOptions,"label":"Attended?","outlined":"","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-0",staticStyle:{"height":"36px","width":"36px","transform":"translateY(-4px)"},attrs:{"disabled":_vm.filteredStudents.length === 0,"icon":"","color":"success"}},on),[_c('v-icon',[_vm._v("fal fa-file-excel")])],1)]}}])},[_c('span',[_vm._v("Export Current List to Excel")])])]},proxy:true}]),model:{value:(_vm.eventAttended),callback:function ($$v) {_vm.eventAttended=$$v},expression:"eventAttended"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.filteredStudents,"headers":_vm.headers},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('v-alert',{attrs:{"type":"info","outlined":""}},[_vm._v("Loading data...")]):_c('v-alert',{attrs:{"type":"error","outlined":""}},[_vm._v("No students found matching the selected filter")])]},proxy:true},{key:"item.major",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.major.join('; ')))]}},{key:"item.minor",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.minor.join('; ')))]}},{key:"item.advisor",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.advisor))]}},{key:"item.courses",fn:function(ref){
var item = ref.item;
return _vm._l((item.courses),function(ref){
var title = ref.title;
var crn = ref.crn;
return _c('v-chip',{key:crn,attrs:{"label":"","outlined":"","small":""}},[_vm._v(_vm._s(title))])})}},{key:"item.events",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.events.length || 0))]}},{key:"item.in",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getEventField(item.events, 'in')))]}},{key:"item.out",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getEventField(item.events, 'out')))]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getEventField(item.events, 'duration')))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }